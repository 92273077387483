<template>
  <!-- Root element of PhotoSwipe. Must have class pswp. -->
  <div
    ref="pswpElement"
    class="pswp"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <!-- Background of PhotoSwipe.
         It's a separate element as animating opacity is faster than rgba(). -->
    <div class="pswp__bg"></div>

    <!-- Slides wrapper with overflow:hidden. -->
    <div class="pswp__scroll-wrap">
      <!-- Container that holds slides.
            PhotoSwipe keeps only 3 of them in the DOM to save memory.
            Don't modify these 3 pswp__item elements, data is added later on. -->
      <div class="pswp__container">
        <div class="pswp__item"></div>
        <div class="pswp__item"></div>
        <div class="pswp__item"></div>
        <div class="pswp__item"></div>
      </div>

      <!-- Default (PhotoSwipeUI_Default) interface on top of sliding area. Can be changed. -->
      <div class="pswp__ui pswp__ui--hidden">
        <div class="pswp__top-bar">
          <!--  Controls are self-explanatory. Order can be changed. -->

          <div class="pswp__counter"></div>

          <button
            class="pswp__button pswp__button--close"
            title="Close (Esc)"
          ></button>

          <button
            class="pswp__button pswp__button--share"
            title="Share"
          ></button>

          <button
            class="pswp__button pswp__button--fs"
            title="Toggle fullscreen"
          ></button>

          <button
            class="pswp__button pswp__button--zoom"
            title="Zoom in/out"
          ></button>

          <!-- Preloader demo https://codepen.io/dimsemenov/pen/yyBWoR -->
          <!-- element will get class pswp__preloader--active when preloader is running -->
          <div class="pswp__preloader">
            <div class="pswp__preloader__icn">
              <div class="pswp__preloader__cut">
                <div class="pswp__preloader__donut"></div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div
          class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap"
        >
          <div class="pswp__share-tooltip"></div>
        </div> -->

        <button
          class="pswp__button pswp__button--arrow--left"
          title="Previous (arrow left)"
        ></button>

        <button
          class="pswp__button pswp__button--arrow--right"
          title="Next (arrow right)"
        ></button>

        <div class="pswp__caption">
          <div class="pswp__caption__center"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  onMounted,
  watch,
  toRef,
  onBeforeUnmount,
  PropType,
} from 'vue'
// import PhotoSwipeFn from 'photoswipe'
// import { PhotoSwipe } from '@types/photoswipe'
// import 'photoswipe/dist/photoswipe.css'
import PhotoSwipeFn from 'photoswipe'
import PhotoSwipeUIDefault from 'photoswipe/dist/photoswipe-ui-default'
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'
import firebase from 'firebase/app'
import 'firebase/storage'

export type Image = {
  src: string
  w: number
  h: number
  title: string
}

export default defineComponent({
  props: {
    isShow: {
      type: Boolean,
      required: true,
      default: false,
    },
    images: {
      type: Array as PropType<string[]>,
      required: false,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const photoswipe = ref<PhotoSwipeFn<PhotoSwipeFn.Options>>()
    const pswpElement = ref<HTMLElement>()

    const options = {
      // index: 3,
      escKey: true,
      closeOnScroll: false,

      // ui option
      timeToIdle: 4000,
    }
    /*
    const items: Image[] = [
      {
        src: 'https://farm4.staticflickr.com/3894/15008518202_c265dfa55f_h.jpg',
        w: 1600,
        h: 1600,
        title: 'This is dummy caption.',
      },
      {
        src: 'https://farm4.staticflickr.com/3902/14985871946_24f47d4b53_h.jpg',
        w: 1600,
        h: 1066,
        title:
          'This is dummy caption. It has been placed here solely to demonstrate the look and feel of finished, typeset text.',
      },
    ]*/

    const getDownloadURL = async (path: string) => {
      const storage = firebase.storage()
      const storageRef = storage.ref(path)

      const url = await storageRef.getDownloadURL()
      const meta = await storageRef.getMetadata()
      console.log(meta)
      return url

      // return src
      // storageRef
      //   .getDownloadURL()
      //   .then((url) => {
      //     src.value = url
      //   })
      //   .catch((error) => {
      //     console.log('Storage error.', error)
      //   })
    }

    const showGallery = async () => {
      console.log('showgallery')
      if (!pswpElement.value) {
        return
      }

      let imgs: Image[] = []
      console.log(props.images)
      for (let img of props.images) {
        imgs.push({
          src: await getDownloadURL(img),
          w: 1000,
          h: 1000,
          title:
            'This is dummy caption. It has been placed here solely to demonstrate the look and feel of finished, typeset text.',
        })
      }
      // await props.images.forEach(async (img) => {
      //   console.log('foreac', img)
      //   imgs.push({
      //     src: await getDownloadURL(img),
      //     w: 1600,
      //     h: 1066,
      //     title:
      //       'This is dummy caption. It has been placed here solely to demonstrate the look and feel of finished, typeset text.',
      //   })
      //   console.log('foeach pushend')
      // })

      // console.log('init photoswipe', items, pswpElement)
      // console.log('init photoswipe', items, imgs)
      photoswipe.value = new PhotoSwipeFn(
        pswpElement.value,
        PhotoSwipeUIDefault,
        imgs,
        options
      )
      console.log(photoswipe)
      console.log('log')
      photoswipe.value.init()

      photoswipe.value.listen('destroy', () => {
        console.log('destroy')
        close()
      })
    }
    const close = () => {
      // photoswipe.value?.close()
      emit('onClose')
    }

    watch(
      () => props.isShow,
      (first, second) => {
        if (first) {
          showGallery()
          console.log(first, second)
        }
      }
    )
    onMounted(() => {
      // if (!pswpElement.value) {
      //   return
      // }
      // const photoswipe = new PhotoSwipeFn(pswpElement.value, false, items, {})
      // console.log(photoswipe)
      // console.log('log')
    })

    onBeforeUnmount(() => {
      console.log('onbeforeunmount')
      close()
    })

    return {
      pswpElement,
    }
  },
})
</script>

<style>
.pswp__img {
  max-width: auto !important;
  object-fit: contain !important;
  height: auto !important;
  max-height: 600px !important;
  /* margin-top: 50px; */
  margin-bottom: 50px;
}
</style>
