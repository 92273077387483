
import {
  defineComponent,
  ref,
  onMounted,
  watch,
  toRef,
  onBeforeUnmount,
  PropType,
} from 'vue'
// import PhotoSwipeFn from 'photoswipe'
// import { PhotoSwipe } from '@types/photoswipe'
// import 'photoswipe/dist/photoswipe.css'
import PhotoSwipeFn from 'photoswipe'
import PhotoSwipeUIDefault from 'photoswipe/dist/photoswipe-ui-default'
import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'
import firebase from 'firebase/app'
import 'firebase/storage'

export type Image = {
  src: string
  w: number
  h: number
  title: string
}

export default defineComponent({
  props: {
    isShow: {
      type: Boolean,
      required: true,
      default: false,
    },
    images: {
      type: Array as PropType<string[]>,
      required: false,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const photoswipe = ref<PhotoSwipeFn<PhotoSwipeFn.Options>>()
    const pswpElement = ref<HTMLElement>()

    const options = {
      // index: 3,
      escKey: true,
      closeOnScroll: false,

      // ui option
      timeToIdle: 4000,
    }
    /*
    const items: Image[] = [
      {
        src: 'https://farm4.staticflickr.com/3894/15008518202_c265dfa55f_h.jpg',
        w: 1600,
        h: 1600,
        title: 'This is dummy caption.',
      },
      {
        src: 'https://farm4.staticflickr.com/3902/14985871946_24f47d4b53_h.jpg',
        w: 1600,
        h: 1066,
        title:
          'This is dummy caption. It has been placed here solely to demonstrate the look and feel of finished, typeset text.',
      },
    ]*/

    const getDownloadURL = async (path: string) => {
      const storage = firebase.storage()
      const storageRef = storage.ref(path)

      const url = await storageRef.getDownloadURL()
      const meta = await storageRef.getMetadata()
      console.log(meta)
      return url

      // return src
      // storageRef
      //   .getDownloadURL()
      //   .then((url) => {
      //     src.value = url
      //   })
      //   .catch((error) => {
      //     console.log('Storage error.', error)
      //   })
    }

    const showGallery = async () => {
      console.log('showgallery')
      if (!pswpElement.value) {
        return
      }

      let imgs: Image[] = []
      console.log(props.images)
      for (let img of props.images) {
        imgs.push({
          src: await getDownloadURL(img),
          w: 1000,
          h: 1000,
          title:
            'This is dummy caption. It has been placed here solely to demonstrate the look and feel of finished, typeset text.',
        })
      }
      // await props.images.forEach(async (img) => {
      //   console.log('foreac', img)
      //   imgs.push({
      //     src: await getDownloadURL(img),
      //     w: 1600,
      //     h: 1066,
      //     title:
      //       'This is dummy caption. It has been placed here solely to demonstrate the look and feel of finished, typeset text.',
      //   })
      //   console.log('foeach pushend')
      // })

      // console.log('init photoswipe', items, pswpElement)
      // console.log('init photoswipe', items, imgs)
      photoswipe.value = new PhotoSwipeFn(
        pswpElement.value,
        PhotoSwipeUIDefault,
        imgs,
        options
      )
      console.log(photoswipe)
      console.log('log')
      photoswipe.value.init()

      photoswipe.value.listen('destroy', () => {
        console.log('destroy')
        close()
      })
    }
    const close = () => {
      // photoswipe.value?.close()
      emit('onClose')
    }

    watch(
      () => props.isShow,
      (first, second) => {
        if (first) {
          showGallery()
          console.log(first, second)
        }
      }
    )
    onMounted(() => {
      // if (!pswpElement.value) {
      //   return
      // }
      // const photoswipe = new PhotoSwipeFn(pswpElement.value, false, items, {})
      // console.log(photoswipe)
      // console.log('log')
    })

    onBeforeUnmount(() => {
      console.log('onbeforeunmount')
      close()
    })

    return {
      pswpElement,
    }
  },
})
