<template>
  <img
    v-if="src"
    :src="src"
    @click="onClick"
    :class="isLink ? `is-clickable` : null"
  />
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue'
import firebase from 'firebase/app'
import 'firebase/storage'

export default defineComponent({
  props: {
    path: {
      type: [String],
      required: false,
    },
    isLink: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const src = ref<string | null>(null)

    const onClick = () => {
      if (props.isLink && src.value) {
        open(src.value, '_blank')
      }
    }
    const getDownloadURL = () => {
      if (!props.path) {
        return
      }
      const storage = firebase.storage()
      const storageRef = storage.ref(props.path)

      storageRef
        .getDownloadURL()
        .then((url) => {
          src.value = url
        })
        .catch((error) => {
          console.log('Storage error.', error)
        })
    }

    onMounted(() => getDownloadURL())

    return {
      src,
      onClick,
    }
  },
})
</script>
