
import { defineComponent, ref, onBeforeMount, onMounted, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Room, Wish } from '@/types/wishlist'
import { roomConverter, wishConverter } from '@/converter/wishlistConverter'
import { updateAmount } from '@/firestore/roomsRepository'

import firebase from 'firebase/app'
import 'firebase/firestore'

import WishListItem from '@/components/wish/WishListItem.vue'
import { numberFormat } from '@/helpers/format'
import { useWishFilter } from '@/composables/wishFilter'

export default defineComponent({
  name: 'WishList',
  components: { WishListItem },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const db = firebase.firestore()
    const room = ref<Room | null>(null)
    // const room = ref<Room | null>(null)
    const { id } = route.params

    const { filterState } = useWishFilter()

    const amountBought = computed(() =>
      numberFormat(room.value?.amount.bought ?? 0)
    )
    const amountWish = computed(() =>
      numberFormat(room.value?.amount.wish ?? 0)
    )

    const wishes = computed(() => {
      if (!filterState.showBought) {
        return room.value?.wishes.filter((w) => w.state === 'wish')
      }

      return room.value?.wishes
      // return room.value?.wishes.filter((w) =>
      //   ['wish', 'bought'].includes(w.state)
      // )
    })

    const onAddWish = () => {
      router.push({ name: 'WishAdd', params: { id: id } })
    }

    const onEditWish = (wish: Wish) => {
      router.push({
        name: 'WishEdit',
        params: { roomId: id, wishId: wish.id as string },
      })
    }

    const onDoneWish = async (wish: Wish) => {
      wish.state = 'bought'
      const roomRef = await db.collection('rooms').doc(id as string)

      await roomRef
        .collection('wishes')
        .withConverter(wishConverter)
        .doc(wish.id)
        .set(wish)

      await updateAmount(id as string)

      /*
      roomRef
        .collection('wishes')
        .withConverter(wishConverter)
        .get()
        .then((wishSnapShot) => {
          let amountBought = 0
          let amountWish = 0
          wishSnapShot.forEach((wishRef) => {
            const wishDoc = wishRef.data()
            // if (!wishDoc) {
            //   return
            // }

            if (wishDoc.state === 'wish') {
              amountWish = amountWish + (wishDoc.price ?? 0)
            } else if (wishDoc.state === 'bought') {
              amountBought = amountBought + (wishDoc.price ?? 0)
            }
          })
          roomRef.set(
            { amount: { bought: amountBought, wish: amountWish } },
            { merge: true }
          )
          console.log(amountBought, amountWish)
        })
*/
      /*
      db.collection('rooms')
        .doc(id as string)
        .collection('wishes')
        .withConverter(wishConverter)
        .doc(wish.id)
        .set(wish)
        */
    }
    const onRevertWish = async (wish: Wish) => {
      wish.state = 'wish'
      console.log('onDone wish')
      await db
        .collection('rooms')
        .doc(id as string)
        .collection('wishes')
        .withConverter(wishConverter)
        .doc(wish.id)
        .set(wish)

      await updateAmount(id as string)
    }

    const onDeleteWish = (wish: Wish) => {
      if (!room.value) {
        return
      }
      const deleteIndex = room.value.wishes.findIndex((w) => w.id === wish.id)
      db.collection('rooms')
        .doc(room.value.id)
        .collection('wishes')
        .doc(wish.id)
        .delete()
        .then(() => {
          // onSnapshotでイベントリッスンしているので明示的に削除しなくていいけど。。
          if (deleteIndex !== -1) {
            console.log(deleteIndex)
            // room.value?.wishes.splice(deleteIndex, 1)
          }
        })
        .catch((error) => {
          alert(error)
        })
    }

    /*
    onBeforeMount(async () => {
      const roomRef = await db.collection('rooms').doc(id as string)

      const roomDoc = await roomRef.withConverter(roomConverter).get()

      room.value = roomDoc.data() ?? null
      console.log('set room', room.value)

      // return await roomRef.withConverter(roomConverter).get()
      roomRef
        .collection('wishes')
        .withConverter(wishConverter)
        .onSnapshot((qs) => {
          const wishes: Wish[] = []
          qs.forEach((wishRef) => {
            const wishDoc = wishRef.data()
            if (wishDoc) {
              // room.value?.wishes.push(wishDoc)
              wishes.push(wishDoc)
            }
          })
          if (room.value) {
            room.value.wishes = wishes
          }
        })
      console.log('return')
    })
*/
    // TODO asyncにしないとif room が消せない。どうにかしたい。roomだけ先に取るか

    onMounted(() => {
      db.collection('rooms')
        .doc(id as string)
        .withConverter(roomConverter)
        // .get()
        .onSnapshot((docRef) => {
          // .then((docRef) => {
          const roomDoc = docRef.data()
          if (roomDoc) {
            console.log('roomDoc', roomDoc)
            room.value = roomDoc
          }

          docRef.ref
            .collection('wishes')
            .withConverter(wishConverter)
            // .get()
            // .then((d) => {
            .onSnapshot((d) => {
              const wishes: Wish[] = []
              d.forEach((dd) => {
                const wishDoc = dd.data()
                if (wishDoc) {
                  // room.value?.wishes.push(wishDoc)
                  wishes.push(wishDoc)
                }
              })
              if (room.value) {
                room.value.wishes = wishes
              }
              // if (roomDoc) {
              //   console.log('roomDoc', roomDoc)
              //   room.value = roomDoc
              // }
            })
          // console.log(docRef.data())
        })
    })

    // .catch((error) => {
    //   console.log(error)
    // })

    // const test = () => {
    //   if (room.value) {
    //     room.value.name = '変更！'
    //     // room.value.wishes.push(new Wish('追加！'))
    //     const newWishes = []
    //     // newWishes.push(new Wish('追加？'))
    //     // room.value.wishes = newWishes
    //   }
    // }

    // room = getRoomWithSnapshot()

    return {
      room,
      wishes,
      filterState,
      amountBought,
      amountWish,
      onAddWish,
      onEditWish,
      onDeleteWish,
      onDoneWish,
      onRevertWish,
    }
  },
})
