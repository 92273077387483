import firebase from 'firebase/app'
import 'firebase/firestore'
import { Room, Wish } from '@/types/wishlist'
import { roomConverter, wishConverter } from '@/converter/wishlistConverter'
import { User } from '@/types/user'
const db = firebase.firestore()
// import { reactive, Ref } from 'vue'

/**
 * 指定ユーザのroom/wishesをリアルタイム通知込みで読込
 * @param rooms
 * @param uid
 */
const getWithSnapshot = async function (
  rooms: Room[],
  // uid: string | undefined
  user: User | null
): Promise<void> {
  // console.log('test', rooms, uid)
  if (!user) {
    console.log('not user')
    return
  }
  console.log(user.email)
  db.collection('rooms')
    .where('roles.' + user.uid, 'in', ['owner', 'invitee'])
    .withConverter(roomConverter)
    .onSnapshot((querySnapShot) => {
      // TODO
      // rooms.splice(0)
      // .get()
      // .then((querySnapShot) => {
      querySnapShot.docs.map((doc) => {
        doc.ref
          .collection('wishes')
          .withConverter(wishConverter)
          .get()
          .then((wishDocs) => {
            const wishes: Wish[] = []
            wishDocs.docs.map((wishDoc) => {
              const wishData = wishDoc.data()
              if (wishData) {
                wishes.push(wishData)
              }
            })
            // console.log(wishes)

            const room = doc.data()
            if (room) {
              querySnapShot.docChanges().forEach((change) => {
                // console.log(change)
                if (change.type === 'added') {
                  if (!rooms.find((f) => f.id === room.id)) {
                    room.wishes.push(...wishes)
                    rooms.push(room)
                  }
                } else if (change.type === 'modified') {
                  const findex = rooms.findIndex((f) => f.id === room.id)
                  if (findex !== -1) {
                    room.wishes.push(...wishes)
                    rooms.splice(findex, 1, room)
                  }
                } else if (change.type === 'removed') {
                  const removeDocIndex = rooms.findIndex(
                    (f) => f.id === change.doc.data().id
                  )
                  if (removeDocIndex !== -1) {
                    rooms.splice(removeDocIndex, 1)
                  }
                }
              })
            }
          })
      })
    })
}

// export default function getRooms(): Promise<firebase.firestore.DocumentData[]> {
// export default async function getRooms(): Promise<firebase.firestore.QuerySnapshot> {
const get = async function get(uid: string | undefined): Promise<Room[]> {
  // db.collection('rooms').withConverter(roomConverter)
  console.log('getroom repository')
  // const rooms: firebase.firestore.DocumentData[] = []
  const rooms: Room[] = []
  if (!uid) {
    return rooms
  }

  const collection = await db
    .collection('rooms')
    .withConverter(roomConverter)
    .get()
  console.log('get collection')

  // const collection = await db.collection('rooms').get()
  for (const roomDoc of collection.docs) {
    console.log(roomDoc.id)
    const wishes: Wish[] = []
    const wishRef = await db
      .collection('rooms')
      .doc(roomDoc.id)
      .collection('wishes')
      .withConverter(wishConverter)
      .get()

    for (const wish of wishRef.docs) {
      wishes.push(wish.data())
      console.log(wish)
    }
    const room = roomDoc.data()
    room.wishes.push(...wishes)
    rooms.push(room)
  }
  return rooms
  /*
  collection.forEach(async (querySnapshot) => {
    // const w: Wish[] = []
    const wishes: Wish[] = []
    const wishRef = await querySnapshot.ref
      .collection('wishes')
      .withConverter(wishConverter)
      .get()
    wishRef.forEach(async (y) => {
      console.log(y)
      wishes.push(await y.data())
      // return y.data()
    })
    console.log('wishes', wishes)
    // return w
    // await console.log('t', querySnapshot)
    // return
  })

  collection.forEach(async (querySnapshot) => {
    // collection.forEach(async (querySnapshot) => {
    const room = querySnapshot.data()
    console.log(room)
    // await querySnapshot.ref.collection('wishes').withConverter(wishConverter)
    // const wishRef = await querySnapshot.ref
    //   .collection('wishes')
    //   .withConverter(wishConverter)
    //   .get()
    /*
    ;(await wishRef).forEach((y) => {
      const yd = y.data()
      if (yd) {
        console.log('yd')
        room.wishes.push(yd)
      }
    })
    */
  /*
    if (room) {
      const t = querySnapshot.ref
        .collection('wishes')
        .withConverter(wishConverter)
        .get()
      t.then((d) => {
        d.forEach((y) => {
          const yd = y.data()
          if (yd) {
            console.log('yd', yd)
            room.wishes.push(yd)
          }
        })
        // console.log(d)
      })
      // t.forEach((v) => {
      //   console.log(v)
      // })
    }
      */
  // const subCollection = await room?.ref
  //   .collection('wishes')
  //   .withConverter(wishConverter)
  //   .get()
  // subCollection?.forEach(async (sc) => {
  //   const scData = sc.data()

  //   if (scData) {
  //     room?.wishes.push(scData)
  //   }
  // })
  // console.log('object', room)
  /*`
    if (room) {
      // rooms[] = room
      // console.log(typeof rooms)
      rooms.push(room)
      // rooms.concat(room)
      console.log('test', typeof rooms, rooms)
    }
    */
  // rooms.push(room)
  // room.wishes = []
  // console.log('querySnapshot', querySnapshot.data())
  // console.log(querySnapshot.ref.path)
  // const subCollection = await querySnapshot.ref.collection('wishes').get()
  // const subCollection = await querySnapshot.ref.collection('wishes').get()
  // console.log(subCollection.size)

  // subCollection.forEach((doc) => {
  // room.wishes.push(doc.data())
  // console.log('pushed', doc)
  // console.log(doc.data())
  // })
  // })

  // console.log('return', rooms)
  // return await Promise.all(rooms)
  // return rooms
  // const data: firebase.firestore.DocumentData[] = []

  // console.log('getRooms')
  // db.collection('rooms')
  //   .get()
  //   .then((querySnapshot) => {
  //     querySnapshot.forEach((doc) => {
  //       console.log(doc.data())
  //     })
  //   })
  // return db.collection('rooms').get()
  // return db.collectionGroup('rooms').get()
  // .then((docRef) => {
  //   docRef.docs
  // })
  // .catch((error) => {
  //   console.log(error)
  // })
  // return data
}

const updateAmount = async function (id: string): Promise<Room | undefined> {
  const roomRef = await db.collection('rooms').doc(id)

  await roomRef
    .collection('wishes')
    .withConverter(wishConverter)
    .get()
    .then((wishSnapShot) => {
      const amount = {
        bought: 0,
        wish: 0,
      }
      wishSnapShot.forEach((wishRef) => {
        const wish = wishRef.data()

        if (wish.state === 'wish') {
          amount.wish += wish.price ?? 0
        } else if (wish.state === 'bought') {
          amount.bought += wish.price ?? 0
        }
      })
      roomRef.set({ amount: amount }, { merge: true })
    })

  return (await roomRef.withConverter(roomConverter).get()).data()
}

export { get, getWithSnapshot, updateAmount }
