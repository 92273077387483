
import {
  computed,
  defineComponent,
  PropType,
  ComputedRef,
  inject,
  ref,
} from 'vue'
import { Wish } from '@/types/wishlist'
import { numberFormat } from '@/helpers/format'
import StorageImage from '@/components/elements/StorageImage.vue'
import AppInputImageResize from '@/components/controls/AppInputImageResize.vue'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'
import { v1 as uuidv1 } from 'uuid'
import { getExtension } from '@/helpers/file'
import { User } from '@/types/user'

import { wishConverter } from '@/converter/wishlistConverter'

import PhotoSwipe from '@/components/PhotoSwipe.vue'

export default defineComponent({
  name: 'WishListItem',
  components: { StorageImage, AppInputImageResize, PhotoSwipe },
  props: {
    roomId: {
      type: String,
      required: true,
    },
    wish: {
      type: Object as PropType<Wish>,
      required: true,
    },
  },
  emits: ['edit', 'done', 'revert', 'delete'],
  setup(props, context) {
    // const onShow = () => console.log('show')
    const onEdit = () => context.emit('edit')
    const onDelete = () => context.emit('delete')
    const onDone = () => context.emit('done')
    const onRevert = () => context.emit('revert')
    const isBought = computed(() => props.wish.state === 'bought')
    // TODO
    const user = inject<ComputedRef<User>>('user')

    const isOpenGallery = ref<boolean>(false)

    const showGallery = () => {
      console.log(props.wish.images)
      isOpenGallery.value = true
    }

    // TODO 更新系は場所移動
    const db = firebase.firestore()
    const storage = firebase.storage()
    const storageRef = storage.ref()

    const price = computed(() =>
      props.wish.price ? numberFormat(props.wish.price) : '-'
    )

    const onFileSelected = (file: File) => {
      console.log('onFileSelected call', file)
      if (user) {
        // Image is upload.
        const filename = uuidv1() + '.' + getExtension(file.name)
        const originalName = file.name

        const fileRef = storageRef.child(
          `users/${user.value.uid}/rooms/${props.roomId}/${filename}`
        )
        // console.log(user, `${user.value.uid}/${file.name}`, fileRef)

        fileRef
          .put(file, {
            customMetadata: { originalFileName: originalName },
          })
          .then((snapshot) => {
            let images = props.wish.images ?? []
            images.push(snapshot.metadata.fullPath)

            console.log('images', images)
            db.collection('rooms')
              .doc(props.roomId)
              .collection('wishes')
              .withConverter(wishConverter)
              .doc(props.wish.id)
              .set({ images: images }, { merge: true })
              .then(() => console.log('wish update'))
              .catch((error) => console.log(error))

            // wish.image = {
            //   name: originalName,
            //   path: snapshot.metadata.fullPath,
            // }
            console.log('upload file2', snapshot)
            // context.emit('submit')
          })
      } else {
        // context.emit('submit')
      }
    }

    return {
      // item: props.wish,
      onEdit,
      onDelete,
      onDone,
      onRevert,
      isBought,
      price,
      onFileSelected,
      isOpenGallery,
      showGallery,
    }
  },
})
