/**
 * ファイル名から拡張子を取得します。
 * @param filename
 * @returns string
 */
export const getExtension = (filename: string): string => {
  let ext = (filename.match(/[^\\/]\.([^.\\/]+)$/) || [null]).pop()
  if (ext) {
    ext = ext.toLowerCase()
  }

  return ext ?? ''
}
