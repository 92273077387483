<template>
  <input
    class="file-input"
    @change="onFileSelected"
    type="file"
    accept="image/*"
  />
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'

export default defineComponent({
  props: {
    maxWidth: {
      type: Number,
      required: true,
    },
    maxHeight: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const filename = ref<string>('Select Image...')

    const onFileSelected = (event: Event) => {
      console.log('fileSelected')
      const files = (event.target as HTMLInputElement).files

      console.log(files)
      if (!files) {
        return
      }

      const file = files.item(0)

      if (!file) {
        return
      }

      // console.log('create reader')
      const reader = new FileReader()

      reader.onload = (event) => {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        const image = new Image()
        image.crossOrigin = 'Anonymous'
        console.log('onload', event)
        image.onload = () => {
          // resize width and height.
          let width, height
          if (image.width > image.height) {
            width = props.maxWidth
            height = props.maxWidth * (image.height / image.width)
          } else {
            width = props.maxHeight * (image.width / image.height)
            height = props.maxHeight
          }

          canvas.width = width
          canvas.height = height

          ctx?.drawImage(image, 0, 0, width, height)

          ctx?.canvas.toBlob(
            (blob) => {
              if (!blob) {
                return
              }
              const imageFile = new File([blob], file.name, {
                type: file.type,
                lastModified: file.lastModified,
              })
              filename.value = file.name
              emit('file', imageFile)
            },
            file.type,
            1
          )
        }
        image.src = event.target?.result as string
      }

      reader.readAsDataURL(file)
    }
    /*
    const onFileSelected = (event: Event) => {
      console.log('fileSelected')
      const files = (event.target as HTMLInputElement).files

      if (!files) {
        return
      }

      const file = files.item(0)
      if (!file) {
        return
      }

      filename.value = file.name
      // console.log(file)

      let reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload((e) => {
        let img = new Image()
        img.onload = () => {
          let width = img.width
          let height = img.height
          if (width > this.maxWidth) {
            height = Math.round((height * this.maxWidth) / width)
            width = this.maxWidth
          }

          let canvas = document.createElement('canvas')
          canvas.width = width
          canvas.height = height
          let ctx = canvas.getContext('2d')
          ctx.drawImage(img, 0, 0, width, height)
          ctx.canvas.toBlob(
            (blob) => {
              const imageFile = new File([blob], file.name, {
                type: file.type,
                lastModified: Date.now(),
              })
              this.smallImages.push(imageFile)
            },
            file.type,
            1
          )
        }
      })

      emit('file', file)
    }
*/
    return {
      filename,
      onFileSelected,
    }
  },
})
</script>
