<template>
  <article class="media">
    <figure class="media-left">
      <p class="image is-64x64">
        <storage-image
          @click="showGallery"
          v-if="wish.images && wish.images.length > 0"
          :path="wish.images[0]"
          :isLink="false"
        ></storage-image>
        <img v-else src="../../assets/wish.jpg" />
        <!-- <img src="https://bulma.io/images/placeholders/128x128.png" /> -->
      </p>
    </figure>
    <div class="media-content">
      <div class="content">
        <p @click="onEdit" class="is-clickable">
          <strong> {{ wish.name }}</strong>
        </p>
      </div>
      <nav class="level is-mobile">
        <div class="level-left">
          <p class="level-item">
            {{ price }}<span class="currency-unit">円</span>
          </p>
        </div>
        <div class="level-right">
          <div class="buttons mb-0">
            <!-- <button v-show="!isBought" class="button is-info is-outlined">
              <span class="icon is-small">
                <i class="far fa-image"></i>
              </span>
            </button> -->
            <div class="file pr-5" v-show="!isBought">
              <label class="file-label">
                <app-input-image-resize
                  class="file-input"
                  :maxHeight="1000"
                  :maxWidth="1000"
                  @file="onFileSelected"
                ></app-input-image-resize>
                <!-- <input
                  class="file-input"
                  type="file"
                  name="resume"
                  @change="onFileSelected"
                /> -->
                <a class="button is-info is-outlined file-cta">
                  <span class="icon">
                    <i class="fas fa-cloud-upload-alt"></i>
                  </span>
                  <!-- <span>Click to upload</span> -->
                </a>
              </label>
            </div>
            <button
              v-show="!isBought"
              @click="onEdit"
              class="button is-success is-outlined"
            >
              <span class="icon is-small">
                <i class="far fa-edit"></i>
              </span>
              <!-- <span class="is-hidden-mobile">edit</span> -->
            </button>
            <button
              v-show="!isBought"
              @click="onDone"
              class="button is-primary is-outlined"
            >
              <span class="icon is-small">
                <i class="fas fa-check"></i>
              </span>
              <!-- <span class="is-hidden-mobile">done</span> -->
            </button>
            <button
              v-show="isBought"
              @click="onRevert"
              class="button is-danger is-light"
            >
              <span class="icon is-small">
                <i class="fas fa-undo"></i>
              </span>
              <!-- <span class="is-hidden-mobile">Revert</span> -->
            </button>
          </div>
        </div>
      </nav>
    </div>
    <!-- <div class="media-right">
      <button class="delete"></button>
    </div> -->
    <photo-swipe
      :images="wish.images"
      :isShow="isOpenGallery"
      @onClose="isOpenGallery = false"
    ></photo-swipe>
  </article>

  <!-- !!!!こから以下は未使用 後で削除!!!! -->
  <div v-if="false" class="card is-radiusless">
    <div class="card-content py-2">
      <div class="columns is-vcentered is-mobile is-multiline">
        <div class="column">
          <div class="columns is-gapless">
            <div class="column has-text-left">
              <div class="has-text-weight-semibold is-size-5 vertical-center">
                <figure class="image is-inline-block is-48x48">
                  <storage-image
                    :path="wish.image?.path"
                    :isLink="true"
                  ></storage-image>
                </figure>

                {{ wish.name }}
                <!-- <button
                  class="button delete mt-1 ml-1 is-danger"
                  @click="onDelete"
                ></button> -->
              </div>
            </div>
            <div
              @click="onShow"
              class="column has-text-right has-text-left-mobile"
            >
              <p class="is-size-5">
                {{ price }} <span class="currency-unit">円</span>
              </p>
            </div>
          </div>
        </div>
        <div class="column is-narrow has-text-centered px-0 pb-1">
          <div class="buttons are-small mb-0 is-block-mobile">
            <button
              v-show="!isBought"
              type="button"
              @click="onEdit"
              class="button is-success is-rounded is-block-mobile"
            >
              Edit
            </button>
            <button
              v-show="!isBought"
              type="button"
              @click="onDone"
              class="button is-warning is-rounded is-block-mobile"
            >
              Done
            </button>

            <button
              v-show="isBought"
              type="button"
              @click="onRevert"
              class="button is-rounded is-danger is-light"
            >
              Revert
            </button>
            <!-- <button class="button is-normal">Normal</button>
            <button class="button">Small</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  PropType,
  ComputedRef,
  inject,
  ref,
} from 'vue'
import { Wish } from '@/types/wishlist'
import { numberFormat } from '@/helpers/format'
import StorageImage from '@/components/elements/StorageImage.vue'
import AppInputImageResize from '@/components/controls/AppInputImageResize.vue'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'
import { v1 as uuidv1 } from 'uuid'
import { getExtension } from '@/helpers/file'
import { User } from '@/types/user'

import { wishConverter } from '@/converter/wishlistConverter'

import PhotoSwipe from '@/components/PhotoSwipe.vue'

export default defineComponent({
  name: 'WishListItem',
  components: { StorageImage, AppInputImageResize, PhotoSwipe },
  props: {
    roomId: {
      type: String,
      required: true,
    },
    wish: {
      type: Object as PropType<Wish>,
      required: true,
    },
  },
  emits: ['edit', 'done', 'revert', 'delete'],
  setup(props, context) {
    // const onShow = () => console.log('show')
    const onEdit = () => context.emit('edit')
    const onDelete = () => context.emit('delete')
    const onDone = () => context.emit('done')
    const onRevert = () => context.emit('revert')
    const isBought = computed(() => props.wish.state === 'bought')
    // TODO
    const user = inject<ComputedRef<User>>('user')

    const isOpenGallery = ref<boolean>(false)

    const showGallery = () => {
      console.log(props.wish.images)
      isOpenGallery.value = true
    }

    // TODO 更新系は場所移動
    const db = firebase.firestore()
    const storage = firebase.storage()
    const storageRef = storage.ref()

    const price = computed(() =>
      props.wish.price ? numberFormat(props.wish.price) : '-'
    )

    const onFileSelected = (file: File) => {
      console.log('onFileSelected call', file)
      if (user) {
        // Image is upload.
        const filename = uuidv1() + '.' + getExtension(file.name)
        const originalName = file.name

        const fileRef = storageRef.child(
          `users/${user.value.uid}/rooms/${props.roomId}/${filename}`
        )
        // console.log(user, `${user.value.uid}/${file.name}`, fileRef)

        fileRef
          .put(file, {
            customMetadata: { originalFileName: originalName },
          })
          .then((snapshot) => {
            let images = props.wish.images ?? []
            images.push(snapshot.metadata.fullPath)

            console.log('images', images)
            db.collection('rooms')
              .doc(props.roomId)
              .collection('wishes')
              .withConverter(wishConverter)
              .doc(props.wish.id)
              .set({ images: images }, { merge: true })
              .then(() => console.log('wish update'))
              .catch((error) => console.log(error))

            // wish.image = {
            //   name: originalName,
            //   path: snapshot.metadata.fullPath,
            // }
            console.log('upload file2', snapshot)
            // context.emit('submit')
          })
      } else {
        // context.emit('submit')
      }
    }

    return {
      // item: props.wish,
      onEdit,
      onDelete,
      onDone,
      onRevert,
      isBought,
      price,
      onFileSelected,
      isOpenGallery,
      showGallery,
    }
  },
})
</script>
